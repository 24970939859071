// ** React Imports
import { Fragment, useState, useRef } from 'react'

// ** Vertical Menu Items Array
import navigation from '@src/navigation/vertical'
import FooterVertical from 'navigation/vertical/FooterVertical'

// ** Third Party Components
import classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'

// ** Vertical Menu Components
import VerticalMenuHeader from './VerticalMenuHeader'
import VerticalNavMenuItems from './VerticalNavMenuItems'
import themeConfig from '@configs/themeConfig';
import { useSelector } from 'react-redux';
import { Carousel } from 'antd';

const Sidebar = props => {
  // ** Props
  const { menuCollapsed, routerProps, menu, currentActiveItem, skin } = props

  const permissionArr = useSelector(state => state.authHddv?.roleData)
  const navigationPermission = navigation.map(item => {
    let newItem = { ...item }
    if (item.permissions && !permissionArr?.includes(item.permissions)) {
      newItem["isHidden"] = true
    } else {
      newItem["isHidden"] = false
    }
    if (newItem.children && newItem.children?.length > 0) {
      const childrenPermission = newItem.children.map(item => {
        let newItem = { ...item }
        if (item.permissions && !permissionArr?.includes(item.permissions)) {
          newItem["isHidden"] = true
        } else {
          newItem["isHidden"] = false
        }
        return newItem
      })
      newItem["children"] = childrenPermission
      if (childrenPermission.every(e => e.isHidden === true)) newItem["isHidden"] = true
    }
    return newItem
  })

  // ** States
  const [groupOpen, setGroupOpen] = useState([])
  const [groupActive, setGroupActive] = useState([])
  const [activeItem, setActiveItem] = useState(null)

  // ** Menu Hover State
  const [menuHover, setMenuHover] = useState(false)

  // ** Ref
  const shadowRef = useRef(null)

  // ** Function to handle Mouse Enter
  const onMouseEnter = () => {
    if (menuCollapsed) {
      setMenuHover(true)
    }
  }

  // ** Scroll Menu
  const scrollMenu = container => {
    if (shadowRef && container.scrollTop > 0) {
      if (!shadowRef.current.classList.contains('d-block')) {
        shadowRef.current.classList.add('d-block')
      }
    } else {
      if (shadowRef.current.classList.contains('d-block')) {
        shadowRef.current.classList.remove('d-block')
      }
    }
  }

  return (
    <Fragment>
      <div
        className={classnames('main-menu menu-fixed menu-accordion menu-shadow d-flex flex-column', {
          expanded: menuHover || menuCollapsed === false,
          'menu-light': skin !== 'semi-dark' && skin !== 'dark',
          'menu-dark': skin === 'semi-dark' || skin === 'dark'
        })}
        onMouseEnter={onMouseEnter}
        onMouseLeave={() => setMenuHover(false)}
      >
        {menu ? (
          menu
        ) : (
          <Fragment>
            {/* Vertical Menu Header */}
            <VerticalMenuHeader setGroupOpen={setGroupOpen} menuHover={menuHover} {...props} />
            {/* Vertical Menu Header Shadow */}
            <div className='shadow-bottom' ref={shadowRef}></div>
            {/* Perfect Scrollbar */}
            <PerfectScrollbar
              className='main-menu-content'
              options={{ wheelPropagation: false }}
              onScrollY={container => scrollMenu(container)}
            >
              <ul className='navigation navigation-main'>
                <VerticalNavMenuItems
                  items={navigationPermission}
                  groupActive={groupActive}
                  setGroupActive={setGroupActive}
                  activeItem={activeItem}
                  setActiveItem={setActiveItem}
                  groupOpen={groupOpen}
                  setGroupOpen={setGroupOpen}
                  routerProps={routerProps}
                  menuCollapsed={menuCollapsed}
                  menuHover={menuHover}
                  currentActiveItem={currentActiveItem}
                />
              </ul>
            </PerfectScrollbar>
            {(menuHover || menuCollapsed === false) &&
              <Carousel autoplay effect="fade">
                <div className='navigation navigation-main d-flex justify-content-center'>
                  <img id='navigation-img-footer' src={themeConfig.app.appLogoImageFooter} alt='footer_img' />
                </div>
                <div className='navigation navigation-main d-flex justify-content-center'>
                  <img id='navigation-img-footer' style={{ aspectRatio: "125/74" }} src={"https://cyberlotus.com/wp-content/uploads/2023/06/web-800-x445.jpg"} alt='footer_img' />
                </div>
                {/* <div className='navigation navigation-main d-flex justify-content-center'>
                  <img id='navigation-img-footer' src={themeConfig.app.appLogoImageFooter} alt='footer_img' />
                </div> */}
                {/* <div className='navigation navigation-main d-flex justify-content-center'>
                  <img id='navigation-img-footer' style={{ aspectRatio: "125/74" }} src={"https://bhd.1cdn.vn/2023/01/08/files-library-images-site-1-20230108-web-net-trong-treo-cua-nu-than-thanh-xuan-the-he-moi-32-204657.jpg"} alt='footer_img' />
                </div>
                <div className='navigation navigation-main d-flex justify-content-center'>
                  <img id='navigation-img-footer' style={{ aspectRatio: "125/74" }} src={"https://bhd.1cdn.vn/2023/01/08/files-library-images-site-1-20230108-web-net-trong-treo-cua-nu-than-thanh-xuan-the-he-moi-32-204655.jpg"} alt='footer_img' />
                </div>
                <div className='navigation navigation-main d-flex justify-content-center'>
                  <img id='navigation-img-footer' style={{ aspectRatio: "125/74" }} src={"https://bhd.1cdn.vn/2023/01/08/files-library-images-site-1-20230108-web-net-trong-treo-cua-nu-than-thanh-xuan-the-he-moi-32-204728.jpg"} alt='footer_img' />
                </div>
                <div className='navigation navigation-main d-flex justify-content-center'>
                  <img id='navigation-img-footer' style={{ aspectRatio: "125/74" }} src={"https://cdn.phunuvagiadinh.vn/kimquynh_btv/auto/7_1_2023/hoa-ngu-8-2023-01-08-22-32.jpg"} alt='footer_img' />
                </div> */}
              </Carousel>
            }
            {/* <ul className='navigation navigation-main'>
              <VerticalNavMenuItems
                items={FooterVertical}
                groupActive={groupActive}
                setGroupActive={setGroupActive}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
                groupOpen={groupOpen}
                setGroupOpen={setGroupOpen}
                routerProps={routerProps}
                menuCollapsed={menuCollapsed}
                menuHover={menuHover}
                currentActiveItem={currentActiveItem}
                {...props}
              />
            </ul> */}
          </Fragment>
        )}
      </div>
    </Fragment>
  )
}

export default Sidebar
